import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
  const _router = inject(Router);

  // every route will be unlocked if there is adminUserToken
  if (sessionStorage.getItem('adminUserToken') !== null) {
    return true;
  } else {
    _router.navigate(['/auth/login']);
    return false;
  }


};
